import "./scss/index.scss";

// import {
//   smallScreen,
//   mediumScreen,
//   smallMediumScreen
// } from "../../globalStyles/scss/variables.scss";

// import { SecondaryMenu_shop_navigation_secondary_items } from "../Footer/gqlTypes/SecondaryMenu";

import * as React from "react";

import { SOCIAL_MEDIA } from "../../core/config";



import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Media from "react-media";
import ReactSVG from "react-svg";

import { TypedSecondaryMenuQuery } from "./queries";

import { NavLink, SocialMediaIcon } from "..";

import { Link } from "react-router-dom";

import footerbanner from "../../images/bannerSample1.jpg";

import "./css/svg-style.css";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      paddingLeft: theme.spacing(4),
    },
    grid2: {
      padding: 0,
    },
    paper: {
      backgroundColor: 'transparent',
      color: theme.palette.text.secondary,
      minWidth: 100,
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    paper2: {
      backgroundColor: 'transparent',
      color: theme.palette.text.secondary,
      minWidth: 100,
      padding: 0,
      textAlign: 'center',
    },
    paper3: {
      backgroundColor: 'transparent',
      color: theme.palette.text.secondary,
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    root: {
      flexGrow: 1,
    },

  })
);




// const DropDown: React.FC = (item: SecondaryMenu_shop_navigation_secondary_items) => {
//   const classes = useStyles();
//   const [isActive, setIsActive] = React.useState(false)

//   return (
//       <>
//           <Grid className= {classes.grid} item xs={"auto"}>
//                 <h4 className="footer-nav__section-header">
//                 <Paper className={classes.paper} elevation={0} onClick={()=>setIsActive(!isActive)}>
//                     <div>
//                     <NavLink item={item} />
//                     </div>
//                 </Paper>
                  
//                 </h4>
//                 <div className="footer-nav__section-content">
//                   { item.children.map(subItem => (
//                     <Paper className={classes.paper2} elevation={0}>
//                     <div>
//                     <p key={subItem.id}><NavLink item={subItem} /></p>
//                     </div>
//                     </Paper>
                    
//                   ))}
//                 </div>
//                 </Grid>
//       </>
//   );
// }

const DXFooterSole: React.FC = () => {

  const termsOfUsePath = `/page/terms-of-use`;
  const privacyPolicyPath = `/page/privacy-policy`;


const classes = useStyles();
 return ( <>
 
  <div className="footer" id="DXfooter">
  <Media query="(max-width: 800px)" render={() =>
          (
            <Container>
    
            <Grid container spacing={3}>
              <Grid item xs={1}>
              </Grid>
              <Grid item xs={10}>
                <Divider />
              </Grid>
              <Grid item xs={1}>

              </Grid>
            </Grid>
            <Grid container justify="center" direction="column" spacing={3}>
              <Grid item>
                <Grid container direction="row" justify="center" alignItems="center">
                
                <Paper className={classes.paper} elevation={0}>
                <div className="footer-nav__section-content">
                  <p>&#169; DieselXchange All rights Reserved.  <Link to={privacyPolicyPath}> Privacy Policy </Link>  | <Link to={termsOfUsePath}> Terms of Use </Link> </p>
                </div>
                </Paper>
                
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justify="center" alignItems="center">
                
                  {SOCIAL_MEDIA.map(medium => (
                    <SocialMediaIcon medium={medium} key={medium.ariaLabel} />
                  ))}
                
                </Grid>  
              </Grid>
            </Grid>
              </Container>
          )}
        />

        <Media query="(min-width: 801px) " render={() =>
          (
      <Container>

      <Grid container spacing={3}>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={10}>
          <Divider />
        </Grid>
        <Grid item xs={1}>

        </Grid>
      </Grid>
      <Grid container justify="space-between" spacing={3}>
        <Grid item>
          <Grid container direction="row" justify="flex-start" alignItems="center">
          
          <Paper className={classes.paper} elevation={0}>
          <div className="footer-nav__section-content">
            <p> &#169; DieselXchange All rights Reserved.  <Link to={privacyPolicyPath}> Privacy Policy </Link> | <Link to={termsOfUsePath}> Terms of Use </Link> </p>
          </div>
          </Paper>
          
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="flex-end" alignItems="center">
          
            {SOCIAL_MEDIA.map(medium => (
              <SocialMediaIcon medium={medium} key={medium.ariaLabel} />
            ))}
          
          </Grid>  
        </Grid>
      </Grid>
        </Container>
          )}
        />
  
      </div>
</>
   
 );
}

interface ArrowProps{
  open: boolean;
}
  

const SVGArrow: React.FC<ArrowProps> = ({open}) => {

    const svgRef = React.useRef();
  return (
    <ReactSVG 
    path={require("../../images/dropdown-caret-down.svg")} 
    className="dropdown-caret"
    ref = {svgRef}
    />
  )
 
}

const DXFooter: React.FC = () => {

  const classes = useStyles();
  const [activeItems, setActiveItems] = React.useState([]);
  const [animate, setAnimate] = React.useState(false);


  const ToggleActiveItems = (updateItem: string) => {
    setAnimate(!animate);
    if (activeItems.includes(updateItem)) {
      setActiveItems(activeItems.filter(value => ( value !== updateItem ) ))
    } else {
      setActiveItems([...activeItems, updateItem])
    }
  }

  const IsActiveItem = (testItem: string) => {
    return activeItems.includes(testItem)
  }

    return (
      <>
      <div
        className="footer__hero"
        style={{backgroundImage: `url(${footerbanner})`}}
      ></div>
      <footer className="footer-nav">
        
        <Media query="(max-width: 800px)" render={() =>
          (
          <>
            <Container>
            <Grid justify="center" direction="column" container>
          <TypedSecondaryMenuQuery>
            {({ data }) => {
              return data.shop.navigation.secondary.items.map(item => (
                <Grid className= {classes.grid} item xs={"auto"}>
                <h4 className="footer-nav__section-header">
                <Paper 
                className={classes.paper}
                elevation={0}
                onClick={() => ToggleActiveItems(item.name)}
                  >

                    <Grid direction="row" justify="center" alignItems="center" container spacing={3}> 
                    {/* <Grid item >
                        <Paper className={classes.paper3} elevation={0}></Paper>
                    </Grid> */}
                      <Grid item >
                       <NavLink item={item} />
                      </Grid>
                      <Grid item>
                        <SVGArrow open={false}/>
                      </Grid>
                    </Grid>
                </Paper>
                
                  
                </h4>
                {IsActiveItem(item.name) &&
                <div className="footer-nav__section-content">
                { item.children.map(subItem => (
                  <Paper className={classes.paper2} elevation={0}>
                  <div>
                  <p key={subItem.id}><NavLink item={subItem} /></p>
                  </div>
                  </Paper>
                  
                ))}
              </div>
               }
                
                </Grid>
              ));
            }}
          </TypedSecondaryMenuQuery>
            </Grid>
          
      </Container>
          </>
          )}
        />

        <Media query="(min-width: 801px) " render={() =>
          (
            <>
              <Container>
        <Grid container spacing={3}>
          <Grid item xs={2}>  
          <Paper className={classes.paper} elevation={0}>
            <ReactSVG path={require("../../images/dxlogo-small.svg")} className="dxlogo-icon" />
          </Paper>
          <Paper className={classes.paper2} elevation={0}>
          <div className="footer-nav__section-content">
          
            <p>26 Andries Street <br></br> 
                Wynberg <br></br>
                P.O.Box 782555 <br></br>
                Sandton, 2196 <br></br>
                011 531 7600
                 </p>
          </div>
          </Paper>


          </Grid>
          <Grid item xs={"auto"}>  
          <Divider orientation="vertical" />
          </Grid>
          <Grid item xs={8}>  
            <Grid container>
          <TypedSecondaryMenuQuery>
            {({ data }) => {
              return data.shop.navigation.secondary.items.map(item => (
                <Grid className= {classes.grid} item xs={"auto"}>
                  <h4 className="footer-nav__section-header">
                    <p key={item.id}><NavLink item={item} /></p>
                  </h4>
                  <div className="footer-nav__section-content">
                    {item.children.map(subItem => (
                      <p key={subItem.id}><NavLink item={subItem} /></p>
                    ))}
                  </div>
                  </Grid>
              ));
            }}
          </TypedSecondaryMenuQuery>
            </Grid>
          </Grid>
          <Grid item xs={2}>  
        
          </Grid>
          
        </Grid>
      </Container>
      
            </>
          )}
        />
      
      <DXFooterSole/>
      </footer>
      </>
    );
};

export default DXFooter;


