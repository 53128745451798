import React, {
  forwardRef,
  RefForwardingComponent,
  useImperativeHandle,
  useState,
} from "react";
import { RouteComponentProps, useHistory } from "react-router";

import { CheckoutReview } from "@components/organisms";
import { statuses as dummyStatuses } from "@components/organisms/DummyPaymentGateway";
import { useCart, useCheckout } from "@sdk/react";
import { CHECKOUT_STEPS } from "@temp/core/config";
import { IFormError } from "@types";

export interface ICheckoutReviewSubpageHandles {
  complete: () => void;
}
interface IProps extends RouteComponentProps<any> {
  selectedPaymentGatewayToken?: string;
  changeSubmitProgress: (submitInProgress: boolean) => void;
}

const CheckoutReviewSubpageWithRef: RefForwardingComponent<
  ICheckoutReviewSubpageHandles,
  IProps
> = (
  { selectedPaymentGatewayToken, changeSubmitProgress, ...props }: IProps,
  ref
) => {
  const history = useHistory();
  const { checkout, payment, createPayment, completeCheckout, availablePaymentGateways, providePaymentGateways } = useCheckout();

  const { totalPrice } = useCart();

  const [errors, setErrors] = useState<IFormError[]>([]);

  const checkoutShippingAddress = checkout?.shippingAddress
    ? {
        ...checkout?.shippingAddress,
        phone: checkout?.shippingAddress?.phone || undefined,
      }
    : undefined;

  const checkoutBillingAddress = checkout?.billingAddress
    ? {
        ...checkout?.billingAddress,
        phone: checkout?.billingAddress?.phone || undefined,
      }
    : undefined;

  const getPaymentMethodDescription = () => {

    if (checkout.paymentMethod?.gateway === "mirumee.payments.dummy") {
      return `Dummy: ${
        dummyStatuses.find(
          status => status.token === checkout?.paymentMethod?.token
        )?.label
      }`;
    } else if (checkout?.paymentMethod?.gateway === "custom.payments.eft")  {
      return `EFT Payment`
    } else if (payment?.creditCard) {
      return `Ending in ${payment?.creditCard.lastDigits}`;
    }
    return ``;
  };
  useImperativeHandle(ref, () => ({
    complete: async () => {
      changeSubmitProgress(true);
      let paymentError = null

      if (!checkout?.paymentMethod?.total || !totalPrice?.gross.amount || checkout?.paymentMethod?.total !== totalPrice?.gross.amount ) {
        const { dataError } = await createPayment(checkout?.paymentMethod.gateway, checkout?.paymentMethod?.token, null);
        paymentError = dataError

      }
      
      if (paymentError){
        const errors = paymentError?.error;
        setErrors(errors)
        changeSubmitProgress(false);
        providePaymentGateways()
      } else {
        const { data, dataError } = await completeCheckout();
        changeSubmitProgress(false);
        const errors = dataError?.error;
        if (errors) {
          setErrors(errors);
        } else {
          setErrors([]);
          history.push({
            pathname: CHECKOUT_STEPS[3].nextStepLink,
            state: {
              id: data?.id,
              orderNumber: data?.number,
              token: data?.token,
            },
          });
        }
      }
      
    },
  }));

  return (
    <CheckoutReview
      {...props}
      shippingAddress={checkoutShippingAddress}
      billingAddress={checkoutBillingAddress}
      shippingMethodName={checkout?.shippingMethod?.name}
      availableGateways = {availablePaymentGateways}
      paymentMethodName={getPaymentMethodDescription()}
      paymentMethod={checkout?.paymentMethod}
      email={checkout?.email}
      errors={errors}
    />
  );
};

const CheckoutReviewSubpage = forwardRef(CheckoutReviewSubpageWithRef);

export { CheckoutReviewSubpage };
