import * as React from "react";

import "../scss/index.scss";

import ReactGA from "react-ga";

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    },
  });

export const AboutUs: React.FC = () => {

    const handleDEClick = () => {
        ReactGA.outboundLink(
            {
              label: 'Opened Diesel-Electric homepage',
            },
            ()=> window.open("https://www.diesel-electric.co.za/")
            );
        
    }

    const handleBSClick = () => {
        ReactGA.outboundLink(
            {
              label: 'Opened Bosch homepage',
            },
            ()=> window.open("https://boschcar.co.za/diesel/")
            );
        
    }

    const classes = useStyles();

    return (
        <>
            <p>
                DieselXchange is a ecommerce platform and logistics company created and supported by the
                 leading Diesel-Electric Distributors and Bosch Diesel Service Centers to fulfil the needs of the subsaharan Automotive Sector for Diesel pumps and Injectors.
                <br></br>
                <br></br>
                At DieselXchange we pride ourselves in making available for purchase and,  in many cases, next day delivery, of a wide range of new and remanufactured diesel pumps and injectors across the country. 
                <br></br>
                <br></br>
                 This is possible through the support of the Diesel-Electric organisation, which are the leading Bosch parts 
                distributors to the Automotive aftermarket in South Africa, and through the expertise of the Bosch Service Centers and their ISO9001 certified workshops. These Centers of Excellence,  remanufacture Bosch units up to factory specifications using state of the art Bosch Test Benches and equipment. The only way to know 
                if a unit is remanufactured to factory specifications is to subject it to a rigorous test
                regiment, this is done as a matter of course. In almost all cases we can supply a test report on request.
                <br></br>
                <br></br>
                For a pump or injector to get the Bosch 12 month countrywide warranty which we provide, it has to be repaired using original Bosch parts, ask for nothing less!!
                <br></br>
                <br></br>
            </p>

            <Grid container 
         spacing={2}
         direction="row"
         alignItems="center"
         justify="center">
            <Grid item xs={10} sm={5}>
            <Card className={classes.root}>
            <CardActionArea onClick={handleDEClick}>
                <CardMedia
                component="img"
                alt="Diesel-Electric"
                height="140"
                image={require("../../../images/DElogo.png")}
                title="Diesel-Electric"
                />
                <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Diesel-Electic
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Find out more about the Diesel-Electric Organisation.
                </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" color="primary" onClick={ handleDEClick}>
                Learn More
                </Button>
            </CardActions>
        </Card>
            </Grid>
            <Grid item xs={10} sm={5}>
            <Card className={classes.root}>
            <CardActionArea onClick={handleBSClick}>
                <CardMedia
                component="img"
                alt="Bosch Diesel Centers"
                height="140"
                image={require("../../../images/boschDieselService.png")}
                title="Bosch service"
                />
                <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Bosch Service Center
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Find out more Bosch Service Centers and Bosch Diesel Centers.
                </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" color="primary" onClick={handleBSClick}>
                Learn More
                </Button>
            </CardActions>
        </Card>
            </Grid>
        </Grid>

        </>
    );
}

export default AboutUs;