import * as React from "react";
import { Route, Switch } from "react-router-dom";

import { RichTextContent } from "@components/atoms";

import AboutUs from "./views/AboutUs";
import ContactUs from "./views/ContactUs";
import ExchangePolicy from "./views/ExchangePolicy";
import HowItWorks from "./views/howItWorks";
import PrivacyPolicy from "./views/PrivacyPolicy";
import ReturnsPolicy from "./views/ReturnsPolicy";
import TermsOfUse from "./views/TermsOfUse";
import Warranty from "./views/Warranty";




const baseurl = "/";
export const contactPath = `${baseurl}page/contact-us`
export const aboutPath = `${baseurl}page/about-us`;
export const howItWorksPath = `${baseurl}page/how-it-works`;
export const exchangePolicyPath = `${baseurl}page/exchange-policy`;
export const returnsPolicyPath = `${baseurl}page/returns-policy`;
export const warrantyPath = `${baseurl}page/warranty`;
export const termsOfUsePath = `${baseurl}page/terms-of-uses`;
export const privacypolicyPath = `${baseurl}page/privacy-policy`;
export const unitCollectionpath = `${baseurl}page/collections`;



interface ViewRouteProps {
    descriptionJson: any;
}

export const ViewRoutes: React.FC<ViewRouteProps> = ({descriptionJson}) => (
<Switch>
    <Route path={contactPath} component={ContactUs}/>
    <Route path={aboutPath} component={AboutUs}/>
    <Route path={howItWorksPath} component={HowItWorks}/>
    <Route path={exchangePolicyPath} component={ExchangePolicy}/>
    <Route path={returnsPolicyPath} component={ReturnsPolicy}/>
    <Route path={warrantyPath} component={Warranty}/>
    <Route path={termsOfUsePath} component={TermsOfUse}/>
    <Route path={privacypolicyPath} component={PrivacyPolicy} />

    <Route>
    <RichTextContent descriptionJson={descriptionJson} />
    </Route>
</Switch>
);
    

export default ViewRoutes;
