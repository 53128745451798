import { Button, Loader } from "@components/atoms";
import { Container } from "@components/templates";
import React from "react";
import { useHistory } from "react-router-dom";


import { useOrderDetails } from "@sdk/react";
import { BASE_URL } from "@temp/core/config";
import { generateGuestOrderDetailsUrl } from "@utils/core";

import * as S from "./styles";
import { IProps } from "./types";

const PaymentSuccessPage: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const token = props?.match?.params?.token

  const { data: order, loading } = useOrderDetails({ 
    token,
  },
  {
    fetchPolicy: "network-only",
  });
  return loading && !order ? (
    <Loader/>
  ):
  (
    <Container>
      <S.Wrapper>
        <S.ThankYouHeader>
        The Payment succeeded 
        </S.ThankYouHeader>
        <S.Paragraph>
          Your order , number <span>{order?.number}</span>, has been paid through the payfast gateway.
        </S.Paragraph>
        <S.Buttons>
          <Button onClick={() => history.push(generateGuestOrderDetailsUrl(token))}>
            Order Details
          </Button>
          <Button onClick={() => history.push(BASE_URL)} color="secondary" fullWidth={true}>
            CONTINUE SHOPPING
          </Button>
        
        </S.Buttons>
      </S.Wrapper>
    

      

    </Container>
  )
};

export { PaymentSuccessPage };
