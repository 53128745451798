import { Formik } from "formik";
import React from "react";

import * as S from "./styles";
import { IProps } from "./types";


/**
 * EFT payment gateway.
 */
const EFTPaymentGateway: React.FC<IProps> = ({
  processPayment,
  formRef,
  formId,
  config,
}: IProps) => {
  return (
    <Formik
      initialValues={{ token: "enabled" }}
      onSubmit={(values, { setSubmitting }) => {
        processPayment();
        setSubmitting(false);
      }}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        isSubmitting,
        isValid,
      }) => (
        <S.Form id={formId} ref={formRef} onSubmit={handleSubmit}>
          <S.Status key={values.token}>

              {  config.map(({field, value}, index)=>
              {

                  if (field === "store_customer_card") {
                    return (null)
                  } else {
                    return (
                      <p key={index}> {field}: {value} </p>
                      )
                  }
                  
                })
              }
  


          </S.Status>
        </S.Form>
      )}
    </Formik>
  );
};


export { EFTPaymentGateway };
