import * as React from "react";

import "../scss/index.scss";

import ReactGA from "react-ga";

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    },
  });

export const warranty: React.FC = () => {

    const handleBoschClick = () => {
        ReactGA.outboundLink(
            {
              label: 'Opened Bosch warrantee page',
            },
            ()=> window.open("https://www.boschaftermarket.com/za/en/services-and-support/warranty/")
            );
        
    }

    const classes = useStyles();

    return (
        <>
            <p>
                The waranty on new pumps and injectors, is covered by us under a Bosch standard 1 year warranty,
                 while the warantee on remanufactured pumps and injectors is covered by the Bosch Service network with a 1 year nation wide warranty.
                  This is also upheld by Bosch themselves because the parts are remanufacuted back to factory specifications and fitted with original bosch parts.
                <br></br>
                <br></br>
            </p>
            <p>
                A quality remanufacure is only possible when you fit genuine bosch parts with the know how of an experienced Bosch service team.
                <br></br>
                <br></br>
            </p>

        <Card className={classes.root}>
            <CardActionArea onClick={handleBoschClick}>
                <CardMedia
                component="img"
                alt="Contemplative Reptile"
                height="140"
                image={require("../../../images/boschBrand.png")}
                title="Contemplative Reptile"
                />
                <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Bosch warranty
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Find out more about the bosch warranty on new and remanufactured parts.
                </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button size="small" color="primary" onClick={handleBoschClick}>
                Learn More
                </Button>
            </CardActions>
        </Card>
        </>
    );
}

export default warranty;