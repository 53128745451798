

import * as React from "react";


import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


interface Props {
  acceptable?: boolean
}

  const useStyles = makeStyles({
    actionArea: {
      backgroundColor: (props: Props) =>
      props.acceptable
      ?  '#deffe1'
      :  '#ffdede',
    },
    media: {
     height: 140,
    },
    root: {
      flexGrow: 1,
      maxWidth: 345,
    },
  });

interface MediaCardProps {
imageUrl?: string;
imageTitle?: string;
bodyText?: string;
acceptable?: boolean;
}
  
export const  AcceptableMediaCard : React.FC<MediaCardProps> = ({imageUrl, imageTitle, bodyText, acceptable}) => {
    const classes = useStyles({acceptable});
    const renderAcceptable = () => (
      acceptable 
      ? "Acceptable"
      : "Not Acceptable" 
    )
    return (
      <Card className={classes.root}>
        <CardActionArea className={classes.actionArea} >
          <CardMedia
            className={classes.media}
            image={imageUrl}
            title={imageTitle}
          />
          <CardContent>
            <Typography gutterBottom variant="h3" component="h3">
              { renderAcceptable()}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {bodyText}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

export default AcceptableMediaCard