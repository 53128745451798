import "../globalStyles/scss/index.scss";

import React from "react";

import { DXFooter, MainMenu, MetaConsumer, OverlayManager } from "../components";
import { Routes } from "./routes";

// import FloatingWhatsApp from "@components/molecules/FloatingWhatsApp/Components/FloatingWhatsApp";
// import  FloatingWhatsApp from "@components/molecules/FloatingWhatsApp";
import  "@components/molecules/FloatingWhatsApp/index.css";

const App: React.FC = () => {
  return (
    <>
      <MetaConsumer />
      <header>
        <MainMenu />
      </header>
      <Routes />
      {/* <Footer /> */}
      

      <DXFooter />
      <OverlayManager />
      
      {/* <FloatingWhatsApp 
        accountName="DieselXchange"
        phoneNumber="+27828152807"
        
      /> */}
    </>
  );
};

export default App;
