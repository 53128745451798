import {Md5} from "md5-typescript";


export const extractGatewayResponse = (data:any): Array<any|undefined>|undefined => {
    const gatewayPayments = data?.orderByToken?.payments.find((payment: { gateway: any; }) => payment.gateway === "custom.payments.payfast")
    if (!gatewayPayments){
        return undefined
    }

    const gatewayResponse = gatewayPayments?.transactions.find((transaction: { kind: any; }) => transaction.kind === "AUTH")

    
    if (gatewayResponse){
        return JSON.parse(gatewayResponse?.gatewayResponse)
    } else {
        return undefined
    }
}

interface DataItemType {
    key: string;
    value: string;
}


export const normalizeQueryData = (data: any, token: string): Array<DataItemType|undefined>|undefined  => {

    const gatewayResponse: any|undefined = extractGatewayResponse(data)

    const normalizedData: any = [
        // Merchant details
        {'key': 'merchant_id', 'value': gatewayResponse? gatewayResponse['Merchant ID']: ''},
        {'key': 'merchant_key', 'value': gatewayResponse? gatewayResponse['Merchant key']: ''},
        {'key': 'return_url', 'value': gatewayResponse? gatewayResponse['Success url'] + token + "/": ''},
        {'key': 'cancel_url', 'value':gatewayResponse? gatewayResponse['Failure url'] + token + "/": ''},
        {'key': 'notify_url', 'value': gatewayResponse? gatewayResponse['Notify url']: ''},
        // Buyer details
        {'key': 'name_first', 'value': data?.orderByToken?.shippingAddress?.firstName},
        {'key': 'name_last', 'value': data?.orderByToken?.shippingAddress?.lastName},
        {'key': 'email_address', 'value': data?.orderByToken?.userEmail},
        // Transaction details
        {'key': 'm_payment_id', 'value': data?.orderByToken?.number},
        {'key': 'amount', 'value': parseFloat(data?.orderByToken?.total?.gross.amount).toString()},
        {'key': 'item_name', 'value': "Order: " + data?.orderByToken?.number},
        {'key': 'item_description', 'value': "Order:" + data?.orderByToken?.number},
        // Custom options
        {'key': "custom_str1", 'value': data?.orderByToken.id},
        {'key': "payment_method", 'value': ""},
    ];

    const cleanedData: Array<DataItemType|undefined> = normalizedData.map((item: DataItemType) =>{
        return {
            'key': item.key,
            'value': item.value.replace(/\s/g, ""),
        }
    })
   
    return cleanedData
} 


export const concatenateData = (data: Array<DataItemType|undefined> ) : string => {

    const encodedData: Array<DataItemType|undefined> = []

    data.map(( item:DataItemType )=> {
        if (item.value !== ""){
            const encodedItem = {
                'key': item.key,
                'value': encodeURIComponent(item.value),
            }
            encodedData.push(encodedItem)
            
        }
        return undefined
    });

    // console.log('concated data for encoding', encodedData)
    const joinedData: Array<string|undefined> = encodedData.map((item: DataItemType)=> item.key + '=' + item.value)
    const concatedURI: string = joinedData.join('&')

    return concatedURI
} 

export const addCheckoutSignatureToData = (data: Array<DataItemType|undefined>|undefined): Array<DataItemType|undefined> =>{
    const concatedData: string = concatenateData(data)
    // console.log('concatenated data', concatedData)
    data.push({"key": 'signature', 'value': Md5.init(concatedData)})
    return data
}

export const isPayfastPayment = (data: any): boolean => {
    const gatewayPayments = data?.orderByToken?.payments.find((payment: { gateway: any; }) => payment.gateway === "custom.payments.payfast")
    if (gatewayPayments){
        return true
    } else {
        return false
    }
}

export const getProcessURLFromData = (data: any) => {
    const gatewayResponse : any = extractGatewayResponse(data)
    const processURL = gatewayResponse ? gatewayResponse.process_url : undefined
    return processURL

};