import { statuses as dummyStatuses } from "@components/organisms/DummyPaymentGateway";
import React from "react";
import * as S from "./styles";
import { IProps } from "./types";





/**
 * Address summary
 */
const PaymentSummary: React.FC<IProps> = ({ availableGateways, paymentGateway, selectedPaymentGatewayToken }: IProps) => {

    const eftConfig = () => {
      const availabeConfig = availableGateways?.find(gateway => gateway.id === 'custom.payments.eft')?.config
      const config = availabeConfig ? availabeConfig : []
       return config
    }


    interface IDField{
      field: string;
      value: string;
    }

    const gatewayIsAvailable = availableGateways?.some(gateway => gateway.id === paymentGateway)
        
    const template = () => {
      if ( !gatewayIsAvailable ){
        return (
          <S.Wrapper>
           Payment method no longer Available
          </S.Wrapper>
        )
      }
      if (paymentGateway === 'custom.payments.eft' ) {

        const config : IDField[] = eftConfig()
        const bankName = config.find(obj => obj.field === "Bank name" )?.value
        const branchLocation = config.find(obj => obj.field === "Branch location" )?.value
        const branchCode = config.find(obj => obj.field === "Branch code" )?.value
        const accountNo = config.find(obj => obj.field === "Account number" )?.value
        const accountType = config.find(obj => obj.field === "Account type" )?.value
        return (
          <S.Wrapper>
            Electronic Fund Transfer <br />
            Bank Name: {bankName}  <br />
            Branch Location: {branchLocation}  <br />
            Branch Code: {branchCode}  <br />
            Account No.: {accountNo}  <br />
            Account Type: {accountType}  <br />
          </S.Wrapper>

        )
      } else if (paymentGateway === "mirumee.payments.dummy") {
        return (
          <S.Wrapper>
          Dummy: {
            dummyStatuses.find(
            status => status.token === selectedPaymentGatewayToken
            )?.label
            }
            </S.Wrapper>
        )
      } else if (paymentGateway === "custom.payments.payfast") {
        return (
          <S.Wrapper>
           Payfast credit or debit card
          </S.Wrapper>
        )
      } else if (paymentGateway === "custom.plugin.credit") {
        return (
          <S.Wrapper>
           Pay with DieselXChange Credit Facility
          </S.Wrapper>
        )
      } else {
        return (
          <S.Wrapper>
            {paymentGateway}
          </S.Wrapper>
        )
      }
    }

    return (
       template() 
    );
 }
  

export { PaymentSummary };
