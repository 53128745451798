import React, {useState} from "react";

import { Button } from "@components/atoms";
import { Container } from "@components/templates";

import * as S from "./styles";
import { IProps } from "./types";

import {PFButton} from '@components/molecules/PayfastPayButton';
import {PayfastForm} from '@components/organisms/PayfastForm';

/**
 * Thank you page after completing the checkout.
 */
const ThankYou: React.FC<IProps> = ({
  orderNumber,
  continueShopping,
  orderDetails,
  token,
}: IProps) => {

  const formRef = React.createRef<HTMLFormElement>()
const [formData, setFormData] = useState({error: undefined, data: undefined, loading:true});

  return (
    <Container>
      <S.Wrapper>
        <S.ThankYouHeader>
          Thank you
          <br />
          <span>for your order!</span>
        </S.ThankYouHeader>
        <S.Paragraph>
          Your order number is <span>{orderNumber}</span>
        </S.Paragraph>
        <S.Paragraph>
          We’ve emailed you an order confirmation, and we’ll notify you the when
          order has been shipped.
        </S.Paragraph>
        <S.Buttons>
          <Button onClick={continueShopping} color="secondary" fullWidth={true}>
            CONTINUE SHOPPING
          </Button>
          <Button onClick={orderDetails} fullWidth={true}>
            ORDER DETAILS
          </Button>
        </S.Buttons>
        <S.Buttons>
        <PFButton formRef={formRef} formData={formData} message="Pay Now" />
        </S.Buttons>
      </S.Wrapper>
      <PayfastForm checkoutToken={token} formRef={formRef} formData={formData} setFormData={setFormData}/>
    </Container>
  );
};

export { ThankYou };
