import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {IProps} from './types'

import { Container } from "@components/templates";
import * as S from "./styles";

import {Button, Loader } from "@components/atoms";
import {PFButton} from '@components/molecules/PayfastPayButton';
import {PayfastForm} from '@components/organisms/PayfastForm';


import { useOrderDetails } from "@sdk/react";

import { generateGuestOrderDetailsUrl } from "@utils/core";

const PaymentFailedPage: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const token = props?.match?.params?.token

  const formRef = React.createRef<HTMLFormElement>()
  const [formData, setFormData] = useState({error: undefined, data: undefined, loading:true});
  
  const { data: order, loading } = useOrderDetails({ 
    token, 
  },
  {
    fetchPolicy: "network-only",
  });


  return loading && !order ? (
    <Loader/>
  ):(
    <Container>
      <S.Wrapper>
        <S.ThankYouHeader>
        Payment did not Succeed 
        </S.ThankYouHeader>
        <S.Paragraph>
          Your order, number <span>{order?.number}</span>, has not been paid because the transaction on the payfast gateway was cancelled or did not compelete.
          Please click on the link below to pay for your order.
        </S.Paragraph>
        <S.Buttons>
          <Button onClick={() => history.push(generateGuestOrderDetailsUrl(token))}>
            Order Details
          </Button>
          {order?.paymentStatus !== 'FULLY_CHARGED'? (
            <>
            <PFButton formRef={formRef} formData={formData} message="Pay Now" />
            </>
          ): (
            <>
            </>
          )}
          
        
        </S.Buttons>
      </S.Wrapper>
      
      <PayfastForm checkoutToken={token} formRef={formRef} formData={formData} setFormData={setFormData}/>

      

    </Container>
  )
};


export { PaymentFailedPage };



