import * as React from "react";

import "../scss/index.scss";

import AcceptableMediaCard from "./components/AcceptableMediaCard";

import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    
    media: {
      height: 140,
    },
    paper: {
        marginBottom: 5,
        marginTop: 5,
        
    },
    root: {
        flexGrow: 1,
        maxWidth: 345,
        },
  });

export const ExchangePolicy: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <p>
                Core for injectors and pumps will be accepted back and a credit will be passed if they are sent back and pass all of the following inspection conditions:
            </p>
        <Grid container spacing={3}>
            <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0} />
            <h3>
            1. Identification
            </h3>
            <p>
                The part number must be cearly visible and it must correspond to the item bought.
            </p>
            
             <Paper />
            </Grid>
            <Grid item xs={6}>
                <AcceptableMediaCard 
                acceptable={true}
                imageUrl={require("../../../images/identity1.png")}
                imageTitle={"Clear Identification"}
                bodyText={"The part number on the item is clearly visible with no signes of scuffing or marking, and the item exchanged is the same as the item bought."}
                />
            </Grid>
            <Grid item xs={6}>
                < AcceptableMediaCard 
                acceptable={true}
                imageUrl={require("../../../images/identity2.png")}
                imageTitle={"Scuffed Identification"}
                bodyText={"The part number on the pump is cleary visible."}
                />
            </Grid>
        </Grid>

        <Grid container spacing={3}>
            <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0} />
            <h3>
            2. Completeness
            </h3>
            <p>
                The item must not be sent back dismantled, nor can there be any parts removed.
            </p>
            
             <Paper />
            </Grid>
            <Grid item xs={6}>
                < AcceptableMediaCard 
                acceptable={false}
                imageUrl={require("../../../images/complete1.png")}
                imageTitle={"Dismantled Injector"}
                bodyText={"The Injector is dismantled when it is returned. The parts supplied may or may not be for the same unit."}
                />
            </Grid>
            <Grid item xs={6}>
                < AcceptableMediaCard 
                acceptable={false}
                imageUrl={require("../../../images/complete2.png")}
                imageTitle={"Dismantled Injector"}
                bodyText={"Disasembled and incomplete injector with parts removed."}
                />
            </Grid>
            <Grid item xs={6}>
                < AcceptableMediaCard 
                acceptable={false}
                imageUrl={require("../../../images/complete3.png")}
                imageTitle={"Dismantled pump"}
                bodyText={"Pump Dismantled with items possibly removed."}
                />
            </Grid>
            <Grid item xs={6}>
                < AcceptableMediaCard 
                acceptable={true}
                imageUrl={require("../../../images/complete4.png")}
                imageTitle={"Pump complete"}
                bodyText={"Complete pump returned with no missing parts"}
                />
            </Grid>
        </Grid>

        <Grid container spacing={3}>
            <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0} />
            <h3>
            2. Mechanical Damage
            </h3>
            <p>
                There must be no mechanical damage to the injector body or pump housing or drive flange, related to incorrect handling.
            </p>
            
             <Paper />
            </Grid>
            <Grid item xs={6}>
            < AcceptableMediaCard 
                acceptable={false}
                imageUrl={require("../../../images/damage1.png")}
                imageTitle={"Damaged pump flange"}
                bodyText={"Pump flange is damaged due to incorrect handling."}
                />
            </Grid>
            <Grid item xs={6}>
            < AcceptableMediaCard 
                acceptable={false}
                imageUrl={require("../../../images/damage2.png")}
                imageTitle={"Damaged injector body"}
                bodyText={"Injector body is damaged due to incorrect handling."}
                />
            </Grid>
        </Grid>

        <Grid container spacing={3}>
            <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0} />
            <h3>
            2. Corrosion
            </h3>
            <p>
                Minor corosion on injector body is acceptable but large corronsion on injector body is not. Minor corrosion on pump housing is acceptable but corrosion on the flange is not.
            </p>
            
             <Paper />
            </Grid>
            <Grid item xs={6}>
            < AcceptableMediaCard 
                acceptable={true}
                imageUrl={require("../../../images/corosion1.png")}
                imageTitle={"minor corrosion on injector body"}
                bodyText={"Minor corrosion on injector body."}
                />
            </Grid>
            <Grid item xs={6}>
            < AcceptableMediaCard 
                acceptable={true}
                imageUrl={require("../../../images/corosion2.png")}
                imageTitle={"minor corrosion on injector body"}
                bodyText={"Minor corrosion on injector body."}
                />
            </Grid>
            <Grid item xs={6}>
            < AcceptableMediaCard 
                acceptable={true}
                imageUrl={require("../../../images/corosion3.png")}
                imageTitle={"minor corrosion on pump housing"}
                bodyText={"Minor corrosion on pump housing"}
                />
            </Grid>
            <Grid item xs={6}>
            < AcceptableMediaCard 
                acceptable={false}
                imageUrl={require("../../../images/corosion4.png")}
                imageTitle={"corrosion on pump flange"}
                bodyText={"Corrosion on pump flange."}
                />
            </Grid>
        </Grid>
            

        </>
    );
}

export default ExchangePolicy;