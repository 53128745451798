import * as React from "react";

import "./scss/index.scss";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles({
    
    cardMedia: {
        paddingTop: "44px",
    },
    p: {
        height: "75px",
    },
    root: {
        // maxWidth: 345,
        
      },
  });

const BottomNav = (): JSX.Element => {

    const classes = useStyles();

    return (
        <>
        <div className="front-nav__width_div">

        <Grid container 
        className={classes.cardMedia}
         spacing={ 3 }
         direction="row"
         alignItems="center"
         justify="center">
            <Grid item xs={10} sm={4}>
            <Card className={classes.root}>
            <CardActionArea>
                <CardMedia 
                
                component="img"
                alt="Diesel-Electric"
                height="120"
                image={require("../../images/widgets/24hrs2.png")}
                title=""
                />
                <CardContent >
                <Typography className={classes.p} variant="body2" color="textSecondary" component="p">
                    Have access to stock and shopping 24 hours a day.
                </Typography>
                </CardContent>
            </CardActionArea>
            </Card>
            </Grid>
            <Grid item xs={10} sm={4}>
            <Card className={classes.root}>
            <CardActionArea>
                <CardMedia
                component="img"
                alt="Diesel-Electric"
                height="120"
                image={require("../../images/widgets/delivery.png")}
                title="Delivery"
                />
                <CardContent>
                <Typography className={classes.p} variant="body2" color="textSecondary" component="p">
                    Quick nationwide delivery, Fast delivery option for next day delivery.
                </Typography>
                </CardContent>
            </CardActionArea>
            </Card>
            </Grid>
            <Grid item xs={10} sm={4}>
            <Card className={classes.root}>
            <CardActionArea>
                <CardMedia
                component="img"
                alt="Diesel-Electric"
                height="120"
                image={require("../../images/widgets/cardOptions3.jpg")}
                title=""
                />
                <CardContent>

                <Typography className={classes.p} variant="body2" color="textSecondary" component="p">
                    Secure credit card payment options as well as EFT.
                </Typography>
                </CardContent>
            </CardActionArea>
            </Card>
            </Grid>


        </Grid>

        </div>
        </>
    )
}

export default BottomNav