import { ICheckoutAction, ICheckoutState } from './types'

export const CheckoutReducer = (state: ICheckoutState, action: ICheckoutAction):ICheckoutState => {
    switch (action.type) {
        case 'updateShippingMethod':
            return {
                ...state,
                ShippingMethod: {id: action.payload?.id},
            }
        case 'updatePaymentMethod':
            return {
                ...state,
                PaymentMethod: {...action.payload},
            }
        
            default:
                return state;
    }
}