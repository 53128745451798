import * as React from "react";

import { Link } from "react-router-dom";

import "../scss/index.scss";
import {

aboutPath,
exchangePolicyPath,
unitCollectionpath,
warrantyPath
 } from "../ViewRoutes";

export const HowItWorks: React.FC = () => {

    return (
        <>
            <h4> We sell and exchange new and remanufactured Diesel pumps and injectors from Bosch, Diesel-Electric and Bosch Service centers. With speedy delivery across the country. </h4>
            <p>
                <br></br>
            </p>

            <h3>
            What is exchange?
            </h3>

            <p>
                An exchange purchase allows you to buy a new or remanufacured unit and send your old unit back to us for a refund. This gives you flexibility by not having to wait for your unit to be repaired. <br></br><br></br>
                When you buy a product from us, we charge you the unit price, but you can also see the core price, which is the refund amount you can expect to receive back on return and successful inspection.  When your new unit arives at you,
                you replace it in the box and we will come collect it from you, see <Link style={{ color: 'blue', textDecoration: 'underline' }} to={unitCollectionpath}>Unit Collections</Link>. Please be aware of the conditions under which we will accept back the core, see our <Link style={{ color: 'blue', textDecoration: 'underline' }} to={exchangePolicyPath}>Exchange Policy</Link>. Dont worry, we wont charge you for sending it back.
            <br></br>
            <br></br>
            </p>

            <p> You benefit from: <br></br><br></br></p>
            
                 <p> &nbsp; &nbsp; &nbsp; &#8226;   Little to no down time because you dont have to wait for a repair. </p>
                 <p> &nbsp; &nbsp; &nbsp; &#8226;  Nationwide warranty and support, see <Link style={{ color: 'blue', textDecoration: 'underline' }} to={warrantyPath}>Our Warranty</Link>. </p>
                 <p> &nbsp; &nbsp; &nbsp; &#8226;  Repair up to factory specifications with original parts from reputable suppliers, see  <Link style={{ color: 'blue', textDecoration: 'underline' }} to={aboutPath}>Our Network</Link>.  </p>
            
            
            <h4><br></br>When you think Exchange, think factory quality with no down time!!</h4>


        </>

        
    );
}

export default HowItWorks;