import * as React from "react";


import "../scss/index.scss";




import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PhoneMediaCard from "./components/PhoneMediaCard";

import Typography from '@material-ui/core/Typography';

import Media from "react-media";

const useStyles = makeStyles({
    actionArea: {
        backgroundColor: "#ffffff",
    },
    img:{
        height: 300,
        objectFit: "none",
        objectPosition: "center",
        width: 300,
    },
    media: {
      height: 300,
    },
    mediaSm: {
        height: 300,
        width: 300,
    },
    paper: {
        textAlign: 'center',
      },
    root: {
        display: 'flex',
        flexGrow: 1,
        paddingTop: 20,
    },
  });


export const ContactUs: React.FC = () => {

    const handleMapClick = () => {
        window.open("https://www.google.co.za/maps/place/Diesel+Electric/@-26.1117154,28.0764554,17z/data=!3m1!4b1!4m5!3m4!1s0x1e950d31cbd17e8f:0x4ad63ad7d8f4d925!8m2!3d-26.1117202!4d28.0786441")
    }

    const classes = useStyles();

    return (
        <>
        

        <Grid container 
         spacing={2}
         direction="row"
         alignItems="center"
         justify="center">
            <Grid item xs={10} sm={5}>
            <PhoneMediaCard 
                imageUrl={require("../../../images/callcenter2.png")}
                imageTitle={"Sales"}
                title={"Sales Line"}
                bodyText={"Help with following up a delivery on an order and placing an order."}
                phoneNo={"(011) 531 7600"}
                />
            </Grid>
            <Grid item xs={10} sm={5}>
            <PhoneMediaCard 
                imageUrl={require("../../../images/hotline2.png")}
                imageTitle={"Damaged injector body"}
                title={"Technical Hotline"}
                bodyText={"Help with fitment and removal of a injector or Pump."}
                phoneNo={"(011) 531 7601"}

                />
            </Grid>
        </Grid>

        
        
        <Media query="(max-width: 540px)" render={() =>
          (
            <Grid 
        container
        direction="column"
        alignItems="center"
        justify="center"
        spacing={2}>
            <Grid item xs={10}>
            <Card className={classes.root} elevation={0}>
            <CardActionArea className={classes.actionArea} onClick={handleMapClick}>
            <CardMedia
                className={classes.mediaSm}
                image={require("../../../images/maps.png")}
                title={"Click to open Google Maps"}
            />
            </CardActionArea>
            </Card>
        </Grid>
        <Grid item xs={10}>
        <Typography variant="body2" color="textSecondary" component="p">
            Diesel Electric (Rand) <br></br>
            26 Andries Street <br></br> 
            Wynberg <br></br>
            P.O.Box 782555 <br></br>
            Sandton, 2196 <br></br>
            011 531 7600 <br></br>
            Office Hours: 8:00am - 4:45pm <br></br> 
            monday-friday
                
            </Typography>
        </Grid>
        </Grid>
          )}
        />

        <Media query="(min-width: 541px)" render={() =>
          (
            <Grid 
            container
            direction="row"
            alignItems="center"
            justify="center"
            spacing={2}>
                <Grid item xs={5}>
                <Card className={classes.root} elevation={0}>
                <CardActionArea className={classes.actionArea} onClick={handleMapClick}>
                <CardMedia
                    className={classes.media}
                    image={"../../../images/maps.png"}
                    title={"Click to open Google Maps"}
                />
                </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary" component="p">
                Diesel Electric (Rand) <br></br>
                26 Andries Street <br></br> 
                Wynberg <br></br>
                P.O.Box 782555 <br></br>
                Sandton, 2196 <br></br>
                011 531 7600 <br></br>
                Office Hours: 8:00am - 4:45pm <br></br> 
                monday-friday
                    
                </Typography>
            </Grid>
            </Grid>
            )}
            />
        

        </>
    );
}

export default ContactUs;