import gql from "graphql-tag";


export const getCARTQUERY = (token:string) =>{
    const CARTQUERY = gql`
    {
      orderByToken(token:"${token}"){
        status
        id
        number
        userEmail
        isPaid
        shippingAddress{
          id
          firstName
          lastName
        }
        total{
          gross{
            amount
          }
          net{
            amount
          }
          tax{
            amount
          }
        }
        payments{
          gateway
          chargeStatus
          transactions{
            kind
            token
            gatewayResponse
          }
        }
      }
    }`;
    return CARTQUERY    
};