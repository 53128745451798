

import * as React from "react";


import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles({
  media: {
    height: 140,
  },
  paper: {
    backgroundColor: "transparent",
    paddingTop: 10,
    textAlign: "center",
  },
  root: {
    backgroundColor: "#e0ecff",
    flexGrow: 1,
  },
  });

interface MediaCardProps {
imageUrl?: string;
imageTitle?: string;
bodyText?: string;
title?: string;
phoneNo?: string;
}
  
export const  PhoneMediaCard : React.FC<MediaCardProps> = ({imageUrl, imageTitle, bodyText, phoneNo, title}) => {
    const classes = useStyles();

    return (
      <Card className={classes.root}>
          <CardMedia
            className={classes.media}
            image={imageUrl}
            title={imageTitle}
          />
          <CardContent>
            <Typography gutterBottom variant="h3" component="h3">
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {bodyText}
            </Typography>
            <Paper className={classes.paper} elevation={0}><h4>{phoneNo}</h4></Paper>
          </CardContent>
      </Card>
    );
  }

export default PhoneMediaCard