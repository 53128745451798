import { useEffect, useState } from "react";

import { IItems } from "@sdk/api/Cart/types";
import {IAvailableShippingMethods, ICheckout, IPayment } from "@sdk/api/Checkout/types";
import { CheckoutStep } from "@temp/core/config";

import { useStateValue } from "@sdk/react/components/CheckoutProvider";

export const useCheckoutStepState = (
  items?: IItems,
  checkout?: ICheckout,
  payment?: IPayment,
  availShippingMethods?: IAvailableShippingMethods
): CheckoutStep => {
  const isShippingRequiredForProducts =
    items &&
    items.some(
      ({ variant }) => variant.product?.productType.isShippingRequired
    );

    const [ state ] = useStateValue();
  
  const getStep = () => {
    

    // shipping method is chosen and it is in available methods
    const isShippingInAvailMethods = checkout?.shippingMethod && availShippingMethods && availShippingMethods.some( ({id}) => id === checkout?.shippingMethod?.id)

    // console.log('address: (' + !checkout?.id + ' && ' + !!items + ' && ' + isShippingRequiredForProducts + ' )')

    if (!checkout?.id && items && isShippingRequiredForProducts) {
      return CheckoutStep.Address;
    } else if  (!checkout?.id && items) {
      return CheckoutStep.Payment;
    }

    // console.log('shipping:( ' + !!checkout?.shippingAddress + ' && ' + !!isShippingRequiredForProducts + ' )' )

    const isShippingStep =
      !!checkout?.shippingAddress && isShippingRequiredForProducts

    // console.log('payment :( ' + isShippingStep + ' && ' + isShippingInAvailMethods + ' && ' + !!checkout?.shippingMethod + ' ) || ' + !isShippingRequiredForProducts )
    const isPaymentStep =
      ( isShippingStep && isShippingInAvailMethods && !!checkout?.shippingMethod) || !isShippingRequiredForProducts;

    // console.log('review :( ' + isPaymentStep + ' && ' + !!checkout?.billingAddress + ' && ' + !!checkout?.paymentMethod + ' )' )
    
    const isReviewStep = isPaymentStep && !!checkout?.billingAddress && !!checkout?.paymentMethod


    if (isReviewStep) {
      return CheckoutStep.Review;
    } else if (isPaymentStep) {
      return CheckoutStep.Payment;
    } else if (isShippingStep) {
      return CheckoutStep.Shipping;
    } else {
      return CheckoutStep.Address;
    }
  };

  const [step, setStep] = useState(getStep());

  useEffect(() => {
    const newStep = getStep();
    if (step !== newStep) {
      setStep(newStep);
    }
  }, [checkout, items, payment, state]);

  return step;
};
