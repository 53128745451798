import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import mainbanner from "../../images/banner1.jpg";
import preauth1 from "../../images/preauth1.jpg";
import preauth2 from "../../images/preauth2.jpg";
import preauth3 from "../../images/preauth3.jpg";

import "./scss/index.scss";


import React from 'react';

interface IProps{
    name: string;
}

const BannerCarousel = ( {name} : IProps): JSX.Element => {

    const [CData] = React.useState([
        {
            location: mainbanner,
            name: "pic one",
        },
        {
            location: preauth1,
            name: "preauth1",
        },
        {
            location: preauth2,
            name: "preauth2",
        },
        {
            location: preauth3,
            name: "preauth3",
        },
    ]);

        return (
            <>
            <div className="home-carousel__width__div">
                <Carousel 
                autoPlay={true}
                showStatus={false}
                showThumbs={false}
                infiniteLoop={true}
                showIndicators={false}
                swipeable={true}
                stopOnHover={true}
                interval={5000}
                transitionTime={500}

                >
                    {CData.map(item => (
                        <>
                        <div>
                            <img src={item.location} />
                        </div>
                        </>
                    ))}
                </Carousel>
            </div>
            
            </>
        )
}


export default BannerCarousel