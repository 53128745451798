import React, { useState } from "react";
import {IProps} from './types'

import { Container } from "@components/templates";
import * as S from "./styles";

import {PFButton} from '@components/molecules/PayfastPayButton';
import {PayfastForm} from '@components/organisms/PayfastForm';

import { useCheckout, useOrderDetails } from "@sdk/react";


interface IDField{
  field: string;
  value: string;
}

    
const PayNowPage: React.FC<IProps> = (props: IProps) => {
  const token = props?.match?.params?.token


  const formRef = React.createRef<HTMLFormElement>()
  const [formData, setFormData] = useState({error: undefined, data: undefined, loading:true});
  const [paymentMethod, setPaymentMethod] = useState({error: undefined, data:undefined, loading:true})
  const { availablePaymentGateways } = useCheckout();
  const { data: order } = useOrderDetails({ token });

  const paymentGateways = availablePaymentGateways
    ? availablePaymentGateways
    : [];

  const eftConfig = () => {
    const availabeConfig = paymentGateways?.find(gateway => gateway.id === 'custom.payments.eft')?.config
    const config = availabeConfig ? availabeConfig : []
     return config
  }

  const config : IDField[] = eftConfig()


  const headerMessage = () => {
    if (paymentMethod.loading) {
      return (
        <>

        </>
      )
    }
    if (paymentMethod.data === 'custom.payments.eft'){
      return (
        <>
          Eft Payment
        </>
      )
    } else if (paymentMethod.data === 'custom.payments.payfast') {
      return (
        <>
         Payfast Payment
        </>
      )
    } else {
      return (
        <>
        No Payment Method selected
        </>
      )
    }
  }

  const bodyMessage = () => {
    if (paymentMethod.loading) {
      return (
        <>
        
        </>
      )
    }
    if (paymentMethod.data === 'custom.payments.eft'){
      const bankName = config.find(obj => obj.field === "Bank name" )?.value
      const branchLocation = config.find(obj => obj.field === "Branch location" )?.value
      const branchCode = config.find(obj => obj.field === "Branch code" )?.value
      const accountNo = config.find(obj => obj.field === "Account number" )?.value
      const accountType = config.find(obj => obj.field === "Account type" )?.value
      return (
        <>
          <S.Paragraph>
          Your order <span>{order.number}</span>, with internet payment reference  number <span>IP{order.number}</span>, can be paid into the account: <br /><br />
          Electronic Fund Transfer <br />
          Bank Name: {bankName}  <br />
          Branch Location: {branchLocation}  <br />
          Branch Code: {branchCode}  <br />
          Account No.: {accountNo}  <br />
          Account Type: {accountType}  <br />
        </S.Paragraph>

        </>
      )
    } else if (paymentMethod.data === 'custom.payments.payfast') {
      return (
        <>
         <S.Paragraph>
         Your order, with number <span>{order.number}</span>, can be payment with payfast credit card or Instant EFT.  <br /><br />
        </S.Paragraph>
        <S.Buttons>
        <PFButton formRef={formRef} formData={formData} message="Pay Now" />
        </S.Buttons>
        
        </>
      )
    } else {
      return (
        <>
          This could be because your order was created as a draft order manually. Please contact sales for further information on payment options. 
        </>
      )
    }
  }


  return (
    <Container>
      <S.Wrapper>
        <S.ThankYouHeader>
         {headerMessage()}
        </S.ThankYouHeader>
        {bodyMessage()}
      </S.Wrapper>
      <PayfastForm checkoutToken={token} formRef={formRef} formData={formData} setFormData={setFormData} setPaymentMethod={setPaymentMethod}/>
      

      

    </Container>
  )
};


export { PayNowPage };



