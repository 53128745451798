import "./scss/index.scss";

import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";


import { 
  BannerCarousel,
//  Button, 
  
//  OverlayContext,
// OverlayTheme,
//  OverlayType,
  BottomNav,
  FrontNav,
  Loader, 
  ProductsFeatured,
  
  
 } from "../../components";

import { generateCategoryUrl } from "../../core/utils";

import {
  ProductsList_categories,
  ProductsList_shop,
  ProductsList_shop_homepageCollection_backgroundImage,
} from "./gqlTypes/ProductsList";

// import { structuredData } from "../../core/SEO/Homepage/structuredData";

import noPhotoImg from "../../images/no-photo.svg";

const Page: React.FC<{
  loading: boolean;
  categories: ProductsList_categories;
  backgroundImage: ProductsList_shop_homepageCollection_backgroundImage;
  shop: ProductsList_shop;
}> = ({ loading, categories, backgroundImage, shop }) => {

  const categoriesExist = () => {
    return categories && categories.edges && categories.edges.length > 0;
  };

  return (
    <>
    { loading && !categories ? (
      <Loader />
    ) : (
      <>
      <BannerCarousel name={"hello"}/>

      <ProductsFeatured />

      <FrontNav />

      {/* <div className="home-page__offset__category">
      </div>
        <script className="structured-data-list" type="application/ld+json">
          {structuredData(shop)}
        </script>
        <div
          className="home-page__hero"
          style={
            backgroundImage
              ? { backgroundImage: `url(${backgroundImage.url})` }
              : null
          }
        >
          <div className="home-page__hero-text">
            <div>
              <span className="home-page__hero__title">
                <h1>Dont replace,</h1>
              </span>
            </div>
            <div>
              <span className="home-page__hero__title">
                <h1>Exchange!!</h1>
              </span>
            </div>
          </div> 
          {loading && !categories ? (
              <Loader />
            ) : (
              (categoriesExist() && (
                <Link
                  to={generateCategoryUrl(
                    categories.edges[0].node.id,
                    categories.edges[0].node.name
                  )}
                >
                  <OverlayContext.Consumer>
                    {overlayContext => (
                      <Button onClick={() =>
                        overlayContext.show(OverlayType.search, OverlayTheme.right)
                      }>Search</Button>
                    )}
                  </OverlayContext.Consumer>
                  
                </Link>
              )
            })
          <div className="home-page__hero-action">
            
          </div>
        </div> */}

        
        
        {categoriesExist() && (
          <div className="home-page__categories">
            <div className="container">
              <h3>Shop by category</h3>
              <div className="home-page__categories__list">
                {categories.edges.map(({ node: category }) => (
                  <div key={category.id}>
                    <Link
                      to={generateCategoryUrl(category.id, category.name)}
                      key={category.id}
                    >
                      <div
                        className={classNames(
                          "home-page__categories__list__image",
                          {
                            "home-page__categories__list__image--no-photo": !category.backgroundImage,
                          }
                        )}
                        style={{
                          backgroundImage: `url(${
                            category.backgroundImage
                              ? category.backgroundImage.url
                              : noPhotoImg
                          })`,
                        }}
                      />
                      <h3>{category.name}</h3>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <BottomNav/>
      </>
    )
  }
    

    </>
  );
};

export default Page;
