import { Button } from "@components/atoms";
import { Container } from "@components/templates";
import React from "react";
import { useHistory } from 'react-router-dom';
import * as S from "./styles";


interface IProps {
    token: string
    order: any
}

const PayForOrderButton: React.FC<IProps> = ({token, order}: IProps ) =>{

    
    const history = useHistory();
    const payUrl:string = `/order-pay-now/${token}`

    const paymentsExist: boolean = order.payments.length > 0 ? true : false
    const orderNotPaid: boolean = (order.paymentStatus !== "FULLY_CHARGED") && (order.statusDisplay !== "Canceled")
    if ( orderNotPaid && paymentsExist){
        return (
            <Container>
                <S.Wrapper>
                <S.Paragraph>
                Your order has not been paid
                </S.Paragraph>
                <Button onClick={() => history.push(payUrl)}>Pay Now</Button>
                </S.Wrapper>
            </Container>
            
        )
        } else {
            return (
                <span></span>
            )
        }
        

    

}

export {PayForOrderButton};