import { createBrowserHistory } from "history";

import ReactGA from 'react-ga';

import { GoogleAnalyticsAPIKey } from "./constants";

ReactGA.initialize(GoogleAnalyticsAPIKey);

const history = createBrowserHistory();
history.listen((_location, action) => {

  ReactGA.set({ page: _location.pathname }); // Update the user's current page
  ReactGA.pageview(_location.pathname); // Record a pageview for the given page

  if (["PUSH"].includes(action)) {
    window.scroll({
      behavior: "smooth",
      top: 0,
    });
  }
});


export { history };
