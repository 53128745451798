import * as React from "react";

import "../scss/index.scss";

export const ReturnsPolicy: React.FC = () => {

    return (
        <>
            <p>
            Goods May be returned for a full refund within 7 business days under an acceptable condition as specified below. A refund will be passed once the items has been 
            recieved back by us. Shipping is charged too and from you as part of your order. A refund will be made back onto the payment method that was 
            tendered by the customer. We will not refund onto a payment method other than the one tendered. <br></br>
            </p>
            <p>&nbsp;</p>
            <h3>Acceptable return conditions for new items are:</h3>
            <ol className="article-page__list">
                <li>1. The items must be in the box it was supplied. If it is new, it must be returned in its original undamaged packaging. </li>
                <li>2. The item must not be damaged or have been fitted as to preserve their condition as considered new and unused.</li>
                <li>3. The item must not have been tampered with and no parts may have been removed. </li>
            </ol>
            <p>&nbsp;</p>
            <h3>Acceptable return conditions for refurbished items are:</h3>
            <ol className="article-page__list">
                <li>1. The items must be in the box it was supplied. </li>
                <li>2. The Item must not be damaged and no parts may have been removed or tampered with.</li>
            </ol>
            <p>&nbsp;</p>
            <p>
            You cannot return goods once you have sent your core back. No refund will be given for shipping charges as this service has allready been rendered.
            </p>
        </>
    );
}

export default ReturnsPolicy;