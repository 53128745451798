import { Button } from "@components/atoms";
import React from "react";


interface IProps {
    formData: IFormData;
    formRef: React.RefObject<HTMLFormElement>;
    message: string;
}


interface IFormData{
    error: any | undefined;
    data: any | undefined;
    loading: boolean;
  }


const PFButton: React.FC<IProps> = ({formData, formRef, message}: IProps ) =>{
    
    const {loading, data, error } = formData

    if (loading){
        return (
            <Button disabled>
                {message}
            </Button>
        )
    }
    if (error){
        return (
            <Button disabled>
                {message}
            </Button>
        )
    }
    if (data){
        return (
            <Button onClick={() => {formRef.current?.submit()}}>
                {message}
            </Button>
        )
    } else {
        return (
            <span></span>
        )
    }

}

export {PFButton};