import React, {createContext,SFC ,useContext, useReducer} from 'react';

import { CheckoutPrProps } from "./types"

// @ts-ignore
export const StateContext = createContext();

export const CheckoutProvider: SFC<CheckoutPrProps> = ({reducer, initialState, children}) =>(
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);
export const useStateValue = ():any => useContext(StateContext);