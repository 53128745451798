import {addCheckoutSignatureToData, getProcessURLFromData, isPayfastPayment, normalizeQueryData } from './api';
import { getCARTQUERY } from './queries';
import PayfastFormProps from './types';

import {useSaleorClient} from '@sdk/react/helpers';
import React, {useEffect, useState} from "react";



const PayfastForm: React.FC<PayfastFormProps> = ({ checkoutToken, formRef, formData, setFormData, setPaymentMethod }) => {

  const CARTQUERY = getCARTQUERY(checkoutToken)
  const apclient: any = useSaleorClient().legacyAPIProxy.client

  const {error, data, loading} = formData
  const [processURL, setProcessURL] = useState(undefined)

  useEffect(()=>{
    try{
      const observable = apclient.watchQuery({
        fetchPolicy: "network-only",
        query: CARTQUERY,
      });

    observable.subscribe(
      result => {

          const paymentGateways: Array<any|undefined>|any = result.data?.orderByToken?.payments

          if (setPaymentMethod){
            if (paymentGateways) {
              setPaymentMethod((prevState => ({...prevState, loading: false, data: paymentGateways[0].gateway})))
            } else {
              setPaymentMethod((prevState => ({...prevState, loading: false, data: undefined})))
            }
          }
          
          
          
        
        const cleanedData = normalizeQueryData(result.data, checkoutToken)
        const formData = addCheckoutSignatureToData(cleanedData)
        const processURL = getProcessURLFromData(result.data)
        const isPayfastPaymentVar = isPayfastPayment(result.data)

        if (isPayfastPaymentVar) {
          setFormData(prevState => ({...prevState, loading: false, data:formData}))
        } else {
          setFormData(prevState => ({...prevState, loading: false, data:undefined}))
        }

        setProcessURL(processURL)
      },
      error => {
        // console.log(error)
      }
    );
    } catch (error) {
      // console.log(error)
    }
      

  },[]);

    if (loading) {
      return <span></span>;
    } 
    if (error) {
      return <p>Error :{error}</p>;
    } 

    if (data){
      return (
        <div>
          <form action={processURL} method="POST" ref={formRef}>
          <>
          { 
           data.map( (item, index) => {
            return (
              <input key={index} type="hidden" name={ item.key } value={item.value}></input>
            )
          }  ) }
          </>
          </form>
        </div>
      ); 
    } else {
      return (<span></span>)
    }
    

    
};

export { PayfastForm };
