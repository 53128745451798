import * as React from "react";

import "./scss/index.scss";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useHistory } from "react-router-dom";

import { 

    OverlayContext,
    OverlayTheme,
    OverlayType,
    
    
   } from "../../components";



const useStyles = makeStyles({
    
    cardMedia: {
        paddingTop: "44px",
    },
    p: {
        height: "75px",
    },
    root: {
        // maxWidth: 345,
        
      },
  });

const FrontNav = (): JSX.Element => {

    const classes = useStyles();
    const history = useHistory();


    const supplersInfoHandler = () => {
        history.push('/page/about-us/')
    }

    const contactInfoHandler = () => {
        history.push('/page/contact-us/')
    }


    return (
        <>
        <div className="front-nav__width_div">

        <Grid container 
        className={classes.cardMedia}
         spacing={ 3 }
         direction="row"
         alignItems="center"
         justify="center">
            <Grid item xs={10} sm={4}>
            <Card className={classes.root}>
            <CardActionArea onClick={supplersInfoHandler}>
                <CardMedia 
                
                component="img"
                alt="Diesel-Electric"
                height="120"
                image={require("../../images/widgets/boschHS.png")}
                title=""
                />
                <CardContent >
                <Typography className={classes.p} variant="body2" color="textSecondary" component="p">
                    Have peace of mind knowing you are backed by most knowledgable suppliers in the industry. 
                    Click here to find out more. 
                </Typography>
                </CardContent>
            </CardActionArea>
            </Card>
            </Grid>
            <Grid item xs={10} sm={4}>
            <Card className={classes.root}>

            <OverlayContext.Consumer>
                {
                    overlayContext => (
                        <CardActionArea
                        onClick={()=> {
                            overlayContext.show(OverlayType.search, OverlayTheme.right)
                        }}
                        >
                        <CardMedia
                        component="img"
                        alt="Diesel-Electric"
                        height="120"
                        image={require("../../images/widgets/search2-1.png")}
                        title=""
                        />
                        <CardContent>
                        <Typography className={classes.p} variant="body2" color="textSecondary" component="p">
                            If you have the OE or Bosch part number, click here to search for for an exchange unit now! <br></br><br></br>
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                    )
                }
            
            </OverlayContext.Consumer>


            </Card>
            </Grid>
            <Grid item xs={10} sm={4}>
            <Card className={classes.root}>
            <CardActionArea
                onClick={contactInfoHandler}
            >
                <CardMedia
                component="img"
                alt="Diesel-Electric"
                height="120"
                image={require("../../images/widgets/preaproved2.png")}
                title=""
                />
                <CardContent>

                <Typography className={classes.p} variant="body2" color="textSecondary" component="p">
                    Contact Us for technical or product information on (011) 531 7600 or click the whatsapp button on the right to preautorize your exchange by sending us some pics. <br></br><br></br>
                </Typography>
                </CardContent>
            </CardActionArea>
            </Card>
            </Grid>


        </Grid>

        </div>
        </>
    )
}

export default FrontNav