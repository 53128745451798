import * as React from "react";

import "../scss/index.scss";

// import { makeStyles } from '@material-ui/core/styles';


// const useStyles = makeStyles({
//     root: {
//       maxWidth: 345,
//     },
//   });

export const PrivacyPolicy: React.FC = () => {

    // const handleBoschClick = () => {
    //     window.open("https://www.boschaftermarket.com/za/en/services-and-support/warranty/")
    // }

    // const classes = useStyles();

    return (
        <>
            
        </>
    );
}

export default PrivacyPolicy;